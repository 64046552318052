import { ROUTES } from '~/lib/constants/routes'

export function useLinks() {
  const router = useRouter()
  const localePath = useLocalePath()

  const goToHomepage = function () {
    return router.push(localePath('/'))
  }

  const helpLink = computed(() => localePath(ROUTES.HELP_FAQ))
  const aboutLink = computed(() => localePath(ROUTES.ABOUT))
  const pricesLink = computed(() => localePath(ROUTES.PRICES))
  const howItWorksLink = computed(() => localePath(ROUTES.HOW_IT_WORKS))
  const reviewsLink = computed(() => localePath(ROUTES.REVIEWS))
  const eventDemoOrder = computed(() => localePath(ROUTES.EVENT_DEMO_ORDER))

  const startOrder = function (queryParam = '') {
    return router.push(localePath(`${ROUTES.EVENT_ORDER}${queryParam}`))
  }

  return { goToHomepage, startOrder, helpLink, aboutLink, pricesLink, howItWorksLink, reviewsLink, eventDemoOrder }
}
